import React, {PropsWithChildren, useEffect, useState} from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

// Components
import { Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { checkIsActive, KTSVG } from '../../../helpers';
import { useLayout } from '../../core';

// ConstVariables
import { constVariables } from 'src/constants';

// Styles
import './AsideMenuItem.scss';
import '../../../assets/sass/palette.scss';
import { useCustomEventListener } from 'react-custom-events';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItem: React.FC<PropsWithChildren<Props>> = ({ children, to, title, icon, fontIcon, hasBullet = false }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  const [isOnLiveDashboard, setIsOnLiveDashboard] = useState(false);
  useCustomEventListener('onLiveDashboard', (body) => {
    setIsOnLiveDashboard(true);
  });
  useCustomEventListener('onEndLiveDashboard', () => {
    setIsOnLiveDashboard(false);
  });

  const handleRightClick = (event) => {
    if (isOnLiveDashboard) {
      event.preventDefault();
      window.open(to, '_blank', 'noopener,noreferrer');
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/liveShow/management') {
      setIsOnLiveDashboard(false);
    }
  }, [location.pathname]);
  return (
    <div
      className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')}
      onContextMenu={handleRightClick}
      data-kt-menu-trigger="click"
      data-tip
      data-for={`MAIN-MENU-${title}`}
    >
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <>
            <div data-tip={title} data-for="menuItem">
              <span className="menu-icon">
                <KTSVG path={icon} className="svg-icon-2" />
              </span>
            </div>
            {/* <Tooltip tooltipId="menuItem" className="titleNames" place="right" /> */}
          </>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className="menu-title">{title}</span>
        {isOnLiveDashboard && (
          <Tooltip
            tooltipId={`MAIN-MENU-${title}`}
            place="right"
            content={() => {
              return <>{title}</>;
            }}
          />
        )}
      </Link>
      <div
        className={clsx('menu-sub menu-sub-accordion', {
          'menu-active-bg': isActive
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItem };
