import { useMutation } from '@apollo/client';
import { START_STOP_SELLER_APPSTREAM } from 'src/apollo/mutations/startStopSellerAppStream';
import { envUrls } from 'src/constants';

interface IuseSellerApp {
  startStopLoading: boolean;
  startSellerApp: () => void;
  stopSellerApp: () => Promise<void>;
}

const useSellerApp = (): IuseSellerApp => {
  const [startStopSellerAppStream, { loading: startStopLoading }] = useMutation(START_STOP_SELLER_APPSTREAM, {
    context: {
      Authorization: localStorage.getItem('token') || '',
      tenantid: localStorage.getItem('storeId'),
      'x-api-key': envUrls.livestreamApiKey
    }
  });

  const startSellerApp = () => {
    startStopSellerAppStream({
      variables: {
        input: 'START'
      }
    });
  };
  const stopSellerApp = async () => {
    await startStopSellerAppStream({
      variables: {
        input: 'STOP'
      }
    });
  };

  return {
    startStopLoading,
    startSellerApp,
    stopSellerApp
  };
};

export default useSellerApp;
