import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useQuery, useSubscription } from '@apollo/client';
import { GET_FACEBOOK_CHANNELS, GET_NEXT_SCHEDULED_LIVE_SHOW, GET_STORE_STREAM_CONFIGURATION } from 'src/apollo/queries';

// Components
import LiveShowManagerPresentational from './LiveshowManager.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Types
import { ILiveStreamDetails, LiveShowManagerPresentationalRef, StoreStreamConfiguratorType } from './LiveshowManager.types';
import { useWarmupLiveDashboard } from 'src/utils/hooks/useWarmupLiveDashboard';
import { emitCustomEvent } from 'react-custom-events';
import { WS_EVENT_TYPE, wsDataType } from './LiveshowFeed';
import { useLiveWebSocket } from './hooks/useLiveWebsocket';

const LiveShowManager: FC = () => {
  const history = useHistory();
  const liveManagerPresentationalRef = useRef<LiveShowManagerPresentationalRef>(null);

  useTitle('Liveshow manager');
  useWarmupLiveDashboard();
  const [eventDetails, setEventDetails] = useState();
  const [liveStreamData, setLiveStreamData] = useState<ILiveStreamDetails>({
    id: '',
    products: [],
    startingAt: '',
    endingAt: '',
    thumbnailUrl: '',
    title: ''
  });

  useEffect(() => {
    if (history?.location?.state) {
      const { eventDetails } = history?.location?.state;
      setEventDetails(eventDetails);
    }
  }, []);

  const [showLiveShowModal, setShowLiveShowModal] = useState<boolean>(false);

  const [storeStreamConfigurator, setStoreStreamConfigurator] = useState<StoreStreamConfiguratorType>({
    liveVideo: {
      arn: '',
      ingestServer: '',
      isStreaming: false,
      playbackUrl: '',
      streamKey: ''
    },
    runningStreamSource: null,
    message: '',
    storeId: '',
    runningLiveId: '',
    medialiveChannelState: null,
    previewVideo: {
      arn: '',
      ingestServer: '',
      isStreaming: false,
      playbackUrl: '',
      streamKey: ''
    },
    isLiveOnApp: false,
    isLiveOnFB: false,
    liveStartedAt: '',
    nextShowBlockedTill: null
  });

  useEffect(() => {
    emitCustomEvent('onLiveDashboard', { runningLiveId: storeStreamConfigurator.runningLiveId });
  }, [storeStreamConfigurator]);

  useQuery(GET_FACEBOOK_CHANNELS);

  const { refetch } = useQuery(GET_NEXT_SCHEDULED_LIVE_SHOW, {
    onCompleted: (data) => {
      setLiveStreamData(data?.getNextScheduledLiveShow);
    },
    onError: (error) => {
      console.error('Get Live Stream Error', error);
    },
    fetchPolicy: 'cache-and-network'
  });

  useQuery(GET_STORE_STREAM_CONFIGURATION, {
    onCompleted: (d) => {
      if (d?.getStoreStreamConfiguration) {
        setStoreStreamConfigurator(d?.getStoreStreamConfiguration);
      }
    },
    onError: (e) => {
      console.error('Get Live Stream Error', e);
    },
    fetchPolicy: 'network-only'
  });

  const onWsMessage = useCallback((messages: wsDataType[]) => {
    console.log('message1', messages);

    for (const message of messages) {
      switch (message.type) {
        case WS_EVENT_TYPE.STORE_STREAM_CONFIG_CHANGED:
          console.log('message.data', message.data);
          setStoreStreamConfigurator(message.data);
          break;

        default:
          liveManagerPresentationalRef?.current?.onMessage(message);

          break;
      }
    }
  }, []);
  useLiveWebSocket(onWsMessage);

  return (
    <LiveShowManagerPresentational
      ref={liveManagerPresentationalRef}
      eventDetails={eventDetails}
      nextLiveStream={liveStreamData}
      showLiveShowModal={showLiveShowModal}
      setShowLiveShowModal={setShowLiveShowModal}
      storeStreamConfigurator={storeStreamConfigurator}
      setStoreStreamConfigurator={setStoreStreamConfigurator}
      handleGetNextScheduledLiveShow={refetch}
    />
  );
};

export default LiveShowManager;
