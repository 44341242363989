import {useState, useEffect, FC, useMemo} from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';

// Components
import { Pagination } from 'src/components/molecules';
import { PageHeader, CustomerList, EmptyList } from 'src/components/oraganisms';

// Icons
import { NoCustomerIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CustomerPresentationalProps } from './Customer.type';
import { ColumnType } from 'src/components/molecules/Table/Table.types';

const initialCustomerTableColumns: ColumnType[] = [
  { name: 'Status', status: true },
  { name: 'Phone number', status: true },
  { name: 'Orders', status: true },
  { name: 'Total', status: true },
  { name: 'Joined', status: true },
  { name: 'Location', status: true },
  { name: 'Tags', status: true }
];

const CustomerPagePresentational: FC<CustomerPresentationalProps> = ({
  customerPageInfo,
  setCustomerPageInfo,
  selectedCustomerIDs,
  setSelectedCustomerIDs,
  customerList,
  sortBy,
  setSortBy,
  searchString,
  setSearchstring,
  onRefetchList,
  isLoading,
  totalCount
}) => {
  const history = useHistory();

  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);

  useEffect(() => {
    const tableColumns = localStorage.getItem('customerTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialCustomerTableColumns);
    }
  }, []);

  const isEmptyTable = useMemo(() => {
    return !isLoading && totalCount === 0 && !searchString;
  }, [isLoading, totalCount, searchString])

  return (
    <div className="p-40" id="tablePage">
      <div className={`width-animation w-100 hide-scrollbar min-h-100`}>
        <PageHeader
          pageTitle={constVariables.customer.allCustomers}
          removeExportImport
          isEditFields={!isEmptyTable}
          openEditColumnDropdown={openEditColumnDropdown}
          setOpenEditColumnDropdown={setOpenEditColumnDropdown}
          editColumnData={editColumnData}
          setEditColumnData={setEditColumnData}
        />
        <div className="card w-100 mb-0 p-0">
          <div className="p-t-32 p-x-32">
            {isEmptyTable && (
              <EmptyList
                title={constVariables.emptyDataList.customer}
                description={constVariables.emptyDataList.customerDescription}
                icon={NoCustomerIcon}
              />
            )}
            <CustomerList
              selectedCustomerIDs={selectedCustomerIDs}
              setSelectedCustomerIDs={setSelectedCustomerIDs}
              customerList={customerList}
              sortBy={sortBy}
              setSortBy={setSortBy}
              searchString={searchString}
              setSearchstring={setSearchstring}
              onRefetchList={onRefetchList}
              limitCount={customerPageInfo.limitCount}
              isLoading={isLoading}
              editColumnData={editColumnData}
              totalCount={totalCount}
            />
          </div>

          <div className={clsx({ 'display-none': totalCount === 0 && !searchString })}>
            <Pagination
              skipCount={customerPageInfo.skipCount}
              limitCount={customerPageInfo.limitCount}
              setSkipCount={(num: number) =>
                setCustomerPageInfo((prev) => ({
                  ...prev,
                  skipCount: num
                }))
              }
              total={totalCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerPagePresentational;
