/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, createRef, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_USER_BASIC_INFO } from 'src/apollo/queries';

// Redux
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getUserInfoSuccess, updateUserInfoSuccess } from 'src/redux/reducer/userInfoReducer';

// Components
import { AsideMenu } from './AsideMenu';
import AsideBottomMenu from './AsideBottomMenu';
import { Portal } from 'src/components/molecules';
import NotificationsDropdown from './NotificationsDropdown';

// Hooks && Utils && Helpers
import { useLayout } from '../../core';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';

// Images && Icons
import { AppLogo } from 'src/assets/logo';
import { ArrowIcon, AppNotificationIcon } from 'src/assets/icons';
import { DrawerComponent } from '../../../assets/ts/components';
import { useCustomEventListener } from 'react-custom-events';

const AsideDefault: FC = () => {
  const { config, classes } = useLayout();
  const { aside } = config;
  const dispatch = useAppDispatch();
  const notificationIconRef = createRef<any>();
  const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });
  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  useQuery(GET_USER_BASIC_INFO, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        isBackStoreUser: true
      }
    },
    context: {
      headers: {
        authorization: localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    },
    onCompleted: (res) => {
      dispatch(getUserInfoSuccess(res?.getUserBasicInfo?.userInfo));
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  const handleOpenNotificationDropdown = (e) => {
    e.stopPropagation();
    setShowNotificationDropdown(true);

    if (notificationIconRef.current) {
      const position = notificationIconRef.current.getBoundingClientRect();
      setDropdownPositions({
        top: position.top + position.height + 10,
        left: position.left
      });
    }
  };

  const location = useLocation();

  const [isOnLiveDashboard, setIsOnLiveDashboard] = useState(false);
  useCustomEventListener('onLiveDashboard', (body) => {
    setIsOnLiveDashboard(true);
  });
  useCustomEventListener('onEndLiveDashboard', () => {
    setIsOnLiveDashboard(false);
  });

  useEffect(() => {
    if ('/liveShow/management' === location.pathname && isOnLiveDashboard) {
      const asideElement = document.getElementById('kt_aside');
      if (asideElement) {
        asideElement.classList.remove('aside-hoverable');
      }
    } else {
      const asideElement = document.getElementById('kt_aside');
      if (asideElement && !asideElement.classList.contains('aside-hoverable')) {
        asideElement.classList.add('aside-hoverable');
      }
      setIsOnLiveDashboard(false);
    }
  }, [location.pathname, isOnLiveDashboard]);
  return (
    <div
      id="kt_aside"
      className={clsx('aside d-flex justify-content-between', classes.aside.join(' '))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {role || localStorage.getItem('userRole') ? (
        <>
          <div>
            {/* begin::Brand */}
            <div className="aside-logo animation-smooth flex-column-auto" id="kt_aside_logo">
              {/* begin::Logo */}
              {aside.theme === 'dark' && (
                <Link to="/home">
                  <img alt="Logo" className="h-25px logo" src={toAbsoluteUrl(AppLogo)} />
                </Link>
              )}
              {aside.theme === 'light' && (
                <Link to="/home">
                  <img alt="Logo" className="h-25px logo" src={toAbsoluteUrl(AppLogo)} />
                </Link>
              )}
              {/* end::Logo */}

              {/* begin::Aside toggler */}
              <div className="d-flex align-items-center position-relative">
                {/*<div*/}
                {/*  className="notification-icon cursor-pointer"*/}
                {/*  onClick={handleOpenNotificationDropdown}*/}
                {/*  ref={notificationIconRef}*/}
                {/*>*/}
                {/*  <KTSVG*/}
                {/*    path={AppNotificationIcon}*/}
                {/*    className={`svg-icon-1 me-4 ${showNotificationDropdown ? 'active-icon' : ''}`}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*{showNotificationDropdown && (*/}
                {/*  <Portal id="kt_body">*/}
                {/*    <OutsideClickHandler*/}
                {/*      onOutsideClick={(e: Event) => {*/}
                {/*        e.preventDefault();*/}
                {/*        e.stopPropagation();*/}
                {/*        const target = e.target as HTMLElement;*/}
                {/*        if (target) {*/}
                {/*          const optionText = (target as HTMLElement).textContent;*/}
                {/*          if (optionText === 'Mark as read' || optionText === 'Mark as unread' || optionText === 'Delete') return;*/}
                {/*        }*/}
                {/*        setShowNotificationDropdown(false);*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <NotificationsDropdown*/}
                {/*        showDropdown={showNotificationDropdown}*/}
                {/*        topPosition={dropdownPositions.top}*/}
                {/*        leftPosition={dropdownPositions.left}*/}
                {/*      />*/}
                {/*    </OutsideClickHandler>*/}
                {/*  </Portal>*/}
                {/*)}*/}

                {aside.minimize && !isOnLiveDashboard && (
                  <div
                    id="kt_aside_toggle"
                    className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                    data-kt-toggle="true"
                    data-kt-toggle-state="active"
                    data-kt-toggle-target="body"
                    data-bs-trigger="hover"
                    data-bs-dismiss-="click"
                    data-kt-toggle-name="aside-minimize"
                  >
                    <KTSVG path={ArrowIcon} className={'svg-icon-1 rotate-180'} />
                  </div>
                )}
              </div>
              {/* end::Aside toggler */}
            </div>
            {/* end::Brand */}

            {/* begin::Aside menu  className:flex-column-fluid */}
            <div className="aside-menu">
              <AsideMenu asideMenuCSSClasses={classes.asideMenu} isOnLiveDashboard={isOnLiveDashboard} />
            </div>
            {/* end::Aside menu */}
          </div>
          <AsideBottomMenu />
        </>
      ) : null}
    </div>
  );
};

export { AsideDefault };
