import {FC, useEffect, useMemo} from 'react';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { FilterFields } from 'src/components/molecules/RightFilterMenu/RightFilterMenu.types';

interface ProductFilterChipsProps {
  filters: FilterFields;
  setProductFilters: React.Dispatch<React.SetStateAction<FilterFields>>;
  updateProductFilters: (fieldName, value) => void;
  handleClearProductFilter: () => void;
  setShowFilterChips?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductFilterChips: FC<ProductFilterChipsProps> = ({ filters, handleClearProductFilter, updateProductFilters, setShowFilterChips }) => {
  const renderFilterLabel = (filterLabel) => {
    switch (filterLabel) {
      case 'vendorIDs':
        return 'Vendor';
      case 'tags':
        return 'Tag';
      case 'filterStatus':
        return 'Status';
      case 'productTypes':
        return 'Type';
      case 'collections':
        return 'Collection';
      case 'stockAvailability':
        return 'Stock Availability';
      default:
        return 'archive';
    }
  };

  const renderStockAvailability = (filterData) => {
    switch (filterData) {
      case 'IN_STOCK':
        return 'In-stock';
      case 'OUT_OF_STOCK':
        return 'Out of stock';
      default:
        return 'Low stock';
    }
  };

  const FilterGroup = ({ filterLabel, filterData }) => {
    const removeFilterItem = (filterItem) => {
      const filteredItems = filterData.filter((optionItem) => optionItem.id !== filterItem.id);
      updateProductFilters(filterLabel, filteredItems);
    };

    return (
      <>
        {filterLabel !== 'stockAvailability' &&
          filterData.map((filterItem, index) => (
            <span key={index} className="chip-item">
              <span>{renderFilterLabel(filterLabel)}:&nbsp;</span>
              <span>{filterItem.name}</span>
              <KTSVG path={CloseIcon} className="svg-icon cursor-pointer" onClick={() => removeFilterItem(filterItem)} />
            </span>
          ))}
        {filterLabel === 'stockAvailability' && (
          <span className="chip-item">
            <span>{renderFilterLabel(filterLabel)}:&nbsp;</span>
            <span>{renderStockAvailability(filterData)}</span>
            <KTSVG path={CloseIcon} className="svg-icon cursor-pointer" onClick={() => updateProductFilters(filterLabel, '')} />
          </span>
        )}
      </>
    );
  };

  const hasFilteredOptions = useMemo(() => {
    let has = false;
    const tempFilters = Object.keys(filters);
    for (let i = 0; i < tempFilters?.length; i++) {
      if (filters[tempFilters[i]]?.length > 0 && tempFilters[i] !== 'stockAvailability') has = true;
      else if (filters[tempFilters[i]] !== '' && tempFilters[i] === 'stockAvailability') has = true;
    }
    return has;
  }, [filters]);

  useEffect(() => {
    if (!hasFilteredOptions) {
      setShowFilterChips && setShowFilterChips(false);
    }
  }, [hasFilteredOptions]);

  return (
    <span className="filter-chips">
      {Object.keys(filters).map((filterKey, index) => (
        <FilterGroup key={index} filterLabel={filterKey} filterData={filters[filterKey]} />
      ))}
      {hasFilteredOptions && (
        <span className="clear-all cursor-pointer" onClick={handleClearProductFilter}>
          Clear All
        </span>
      )}
    </span>
  );
};

export default ProductFilterChips;
