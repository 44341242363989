import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_WS_TOKEN } from 'src/apollo/queries/getWsToken';
import { envUrls } from 'src/constants/appUrls';
import { useToast } from 'src/utils/hooks/useToast';
import { wsDataType } from '../LiveshowFeed';

export const useLiveWebSocket = (onMessage: (message: wsDataType[]) => void) => {
  const { showToast } = useToast();
  const [isConnected, setIsConnected] = useState(false);
  const ws = useRef<WebSocket | null>(null);
  const reconnectAttempts = useRef(0);
  const keepAliveInterval = useRef<NodeJS.Timeout | null>(null);

  const { refetch } = useQuery(GET_WS_TOKEN, {
    onCompleted: (data) => {
      if (data?.getWsToken) {
        connectWebSocket(data.getWsToken);
      }
    },
    onError: (error) => console.error(error)
  });

  const connectWebSocket = (token: string) => {
    ws.current = new WebSocket(`${envUrls.liveWebSocketUrl}?authorization=${token}`);

    ws.current.onopen = () => {
      console.log('WebSocket opened');
      setIsConnected(true);
      reconnectAttempts.current = 0; // Reset reconnect attempts on successful connection
      startKeepAlive();
    };

    ws.current.onmessage = (event) => {
      try {
        const receivedData: wsDataType[] = JSON.parse(event.data);
        console.log('Received WebSocket message:', receivedData);
        onMessage(receivedData);
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    ws.current.onerror = (e) => {
      console.error('WebSocket error', e);
      showToast({ errorText: 'Realtime updates unavailable', message: 'Error in connection' });
    };

    ws.current.onclose = () => {
      console.log('WebSocket closed');
      setIsConnected(false);
      stopKeepAlive();
      reconnect();
    };
  };

  const startKeepAlive = () => {
    stopKeepAlive(); // Clear any existing interval
    keepAliveInterval.current = setInterval(() => {
      if (ws.current?.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify({ type: 'ping' })); // Send a keep-alive message
      }
    }, 4 * 60 * 1000); // Send every 4 minutes
  };

  const stopKeepAlive = () => {
    if (keepAliveInterval.current) {
      clearInterval(keepAliveInterval.current);
      keepAliveInterval.current = null;
    }
  };

  const reconnect = () => {
    const delay = Math.min(1000 * 2 ** reconnectAttempts.current, 60000); // Exponential backoff up to 60s
    reconnectAttempts.current += 1;

    console.log(`Reconnecting in ${delay / 1000} seconds...`);
    setTimeout(() => refetch(), delay);
  };

  useEffect(() => {
    return () => {
      ws.current?.close();
      stopKeepAlive();
    };
  }, []);

  const sendMessage = (message: any) => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not open');
    }
  };

  return { sendMessage, isConnected };
};
