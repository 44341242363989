/* eslint-disable dot-notation */
export const ENV = {
  DEV: 'DEV',
  QA: 'QA',
  LOCAL: 'LOCAL',
  STAGING: 'STAGING',
  PROD: 'prod'
};

const TINA_ACCESS_TOKEN =
  'eyJraWQiOiJ1dGVYWlBUOTJnYmNaWDQwY3Rhbk1MUUJ3WTVcLzlMT0pnWEI0d1FVRzg3TT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJiMjlmYWI2NS1jODUwLTRmOWEtYjY3Zi0wYWI3ODc4MGQ3NTQiLCJldmVudF9pZCI6IjE1NmQzMGU1LTk5YjUtNGY4MS05MGVkLTRiYjc2NDRlYTIzMCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3Mzk0NjAwNTEsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xXzZhaGlTaFNVYyIsImV4cCI6MTczOTk2OTUwMCwiaWF0IjoxNzM5OTY1OTAwLCJqdGkiOiIyYjEyODM4MC1lNDhhLTQ1M2ItOTAxNi1mMWViYjBkYmFjN2MiLCJjbGllbnRfaWQiOiI1YjhmbWh2MWczNWtidWg4M3ZkYmF1ZHJuaCIsInVzZXJuYW1lIjoiYjI5ZmFiNjUtYzg1MC00ZjlhLWI2N2YtMGFiNzg3ODBkNzU0In0.Ff9nMHsSuZ3ZLZ1TN7wmoI0wO5kDaLqKOVHYlzrn5bAsJFPEckqvFQ1b1pEobpuemHOjvHMRbAHW3Wcnhdbow7iByB49tjdAd6nXlOv4OfvivWnOAt_Bqr-Q4ZPlk6L24XpuxjQbZXXI_ZaLIm4Gp8_0xWqVsk1lpLynPjBhLMJXjDOKPIRTt6EpSsSfho9suyD4x07NpNcXWqTweRL0-8qGhiEMoBOPkU_n0jmzLlnO1bRWjeYvbbqNzvbYBpsrSp239WeQIoVlwQPY-dUFgnNmulNPTdqwngFDyZOnCQbtrR6S4DfllOg2xphnQzO-1gNXdwUE6EZgYxikR35w4w';

export const localEnvironmentUrls = {
  productsApiHost: 'x6tyxbhfbfemxgvkzl7ymodoc4.appsync-api.ap-south-1.amazonaws.com',
  ordersAPIHost: 'suus5kwauna3hitzmdkza5obx4.appsync-api.ap-south-1.amazonaws.com',
  LiveStreamingAPIHost: 'plu6qoouhbddvokb23fnsbmzqa.appsync-api.ap-south-1.amazonaws.com',
  productsApiUrl: 'http://localhost:3007/graphql',
  authApiUrl: 'https://yfrf2vwcfbb6zpf27fnqdegcay.appsync-api.ap-south-1.amazonaws.com/graphql',
  ordersApiUrl: 'https://suus5kwauna3hitzmdkza5obx4.appsync-api.ap-south-1.amazonaws.com/graphql',
  liveStreamingUrl: 'https://plu6qoouhbddvokb23fnsbmzqa.appsync-api.ap-south-1.amazonaws.com/graphql',
  realTimeLiveStreamingApiHost: 'wss://plu6qoouhbddvokb23fnsbmzqa.appsync-realtime-api.ap-south-1.amazonaws.com/graphql',
  passwordEncryptionKey: 'WeNeedSomeSecureKeyToEncryptPass',
  shippoClientId: '8f3883d75c264920a072d79125bcab02',

  shopifyApiUrl: 'https://vsonvnllqvd4lbf2odx4z7y2gq.appsync-api.ap-south-1.amazonaws.com/graphql',
  // shopifyCallbackUrl: 'https://b987sj0rnk.execute-api.ap-south-1.amazonaws.com/dev/shopify',
  shopifyCallbackUrl: 'http://localhost:4009/dev/shopify',

  /* dev */
  firebaseApiKey: 'AIzaSyD7Ni96kIGlWtHz3ATTWFoYiNctn3_CFHs',
  firebaseAuthDomain: 'stellar-operand-368804.firebaseapp.com',
  firebaseTenantId: 'Tenant1-y0xwux',
  awsRegion: 'ap-south-1',

  /* staging */

  /* 
  firebaseApiKey: 'AIzaSyB0hDy2kkB5IyGsrxReveBjrxRxe6ivH-w',
  firebaseAuthDomain: 'buysocial-staging.firebaseapp.com',
  firebaseTenantId: 'Tenant', // No validation on BE
  awsRegion: 'us-east-1',
  // csvBoxLicenseKey: 'tM1qADHoTOCqpaUO90xG8mIHmc3eAz',
*/

  liveWebSocketUrl: 'wss://40ycp9zuva.execute-api.ap-south-1.amazonaws.com/dev',
  facebookAppId: 525169602445808,
  csvBoxImportURL: 'http://localhost:4000/dev/csvbox/webhook/products',
  onesignalAppId: 'f4a4d524-aad9-4666-ae28-a3cb902d6ed1',
  // csvBoxLicenseKey: 'hyB1sGeHKaGqo8jsDA5jm4meT8tgVf',
  csvBoxLicenseKey: 'hyB1sGeHKaGqo8jsDA5jm4meT8tgVf',
  stripeClientId: 'ca_KZH9OePpyuv00W2iqI5EueNbFQoOvIA0',
  tinaUrl: `https://tina-cloud-starter-kappa-eight-29.vercel.app/admin?token=${TINA_ACCESS_TOKEN}`
};

export const devEnvironmentUrls = {
  productsApiHost: 'x6tyxbhfbfemxgvkzl7ymodoc4.appsync-api.ap-south-1.amazonaws.com',
  ordersAPIHost: 'suus5kwauna3hitzmdkza5obx4.appsync-api.ap-south-1.amazonaws.com',
  LiveStreamingAPIHost: 'plu6qoouhbddvokb23fnsbmzqa.appsync-api.ap-south-1.amazonaws.com',
  productsApiUrl: 'https://x6tyxbhfbfemxgvkzl7ymodoc4.appsync-api.ap-south-1.amazonaws.com/graphql',
  authApiUrl: 'https://abt7ypx5mjcipfjgbvryy2oxlq.appsync-api.ap-south-1.amazonaws.com/graphql',
  ordersApiUrl: 'https://suus5kwauna3hitzmdkza5obx4.appsync-api.ap-south-1.amazonaws.com/graphql',
  liveStreamingUrl: 'https://plu6qoouhbddvokb23fnsbmzqa.appsync-api.ap-south-1.amazonaws.com/graphql',
  realTimeLiveStreamingApiHost: 'wss://plu6qoouhbddvokb23fnsbmzqa.appsync-realtime-api.ap-south-1.amazonaws.com/graphql',
  passwordEncryptionKey: 'WeNeedSomeSecureKeyToEncryptPass',
  shippoClientId: '8f3883d75c264920a072d79125bcab02',
  firebaseApiKey: 'AIzaSyD7Ni96kIGlWtHz3ATTWFoYiNctn3_CFHs',
  firebaseAuthDomain: 'stellar-operand-368804.firebaseapp.com',
  firebaseTenantId: 'Tenant1-y0xwux',
  liveWebSocketUrl: 'wss://40ycp9zuva.execute-api.ap-south-1.amazonaws.com/dev',
  facebookAppId: 525169602445808,
  csvBoxImportURL: 'https://nygs93jdod.execute-api.ap-south-1.amazonaws.com/dev/csvbox/webhook/products',
  onesignalAppId: 'f4a4d524-aad9-4666-ae28-a3cb902d6ed1',
  csvBoxLicenseKey: 'tM1qADHoTOCqpaUO90xG8mIHmc3eAz',
  stripeClientId: 'ca_KZH9OePpyuv00W2iqI5EueNbFQoOvIA0',
  awsRegion: 'ap-south-1',
  shopifyApiUrl: 'https://vsonvnllqvd4lbf2odx4z7y2gq.appsync-api.ap-south-1.amazonaws.com/graphql',
  shopifyCallbackUrl: 'https://b987sj0rnk.execute-api.ap-south-1.amazonaws.com/dev/shopify',
  tiktokApiUrl: 'https://pmhnue2bqrfaboif4fhpbhqyku.appsync-api.ap-south-1.amazonaws.com/graphql',
  tinaUrl: `https://tina-cloud-starter-kappa-eight-29.vercel.app/admin?token=${TINA_ACCESS_TOKEN}`
};

export const qaEnvironmentUrls = {
  productsApiHost: 'j5rro2lpmvfrjlvepg2kipwbxa.appsync-api.ap-south-1.amazonaws.com',
  ordersAPIHost: 'c24gxrt7u5gh3n6xjhkwtrte7e.appsync-api.ap-south-1.amazonaws.com',
  LiveStreamingAPIHost: 'zad5sevi7zhkrdfzenhu4opbge.appsync-api.ap-south-1.amazonaws.com',
  productsApiUrl: 'https://hcawu5p6fzazljyf32diqmtoym.appsync-api.ap-south-1.amazonaws.com/graphql',
  authApiUrl: 'https://p4jhhx4al5f3dg5zvlysxbounq.appsync-api.ap-south-1.amazonaws.com/graphql',
  ordersApiUrl: 'https://c24gxrt7u5gh3n6xjhkwtrte7e.appsync-api.ap-south-1.amazonaws.com/graphql',
  liveStreamingUrl: 'https://zad5sevi7zhkrdfzenhu4opbge.appsync-api.ap-south-1.amazonaws.com/graphql',
  realTimeLiveStreamingApiHost: 'wss://zad5sevi7zhkrdfzenhu4opbge.appsync-realtime-api.ap-south-1.amazonaws.com/graphql',
  passwordEncryptionKey: 'WeNeedSomeSecureKeyToEncryptPass',
  shippoClientId: 'bd15fd76036e40aba0b8a714f3df52bf',
  firebaseApiKey: 'AIzaSyBQFk3cxqCl_4g-bHi2hGvHsJvPd8s2QM8',
  firebaseAuthDomain: 'evident-post-377014.firebaseapp.com',
  firebaseTenantId: 'Tenant1-y0xwu',
  liveWebSocketUrl: 'wss://dr949juzdk.execute-api.ap-south-1.amazonaws.com/qa',
  facebookAppId: '3285298398400956',
  csvBoxImportURL: 'https://m0z3avdot8.execute-api.ap-south-1.amazonaws.com/qa/csvbox/webhook/products',
  onesignalAppId: 'f9c81444-96bf-4349-a20f-0debf6541887',
  csvBoxLicenseKey: 'rpesnOqFjXHHBgGpd7uoZgis7plRVD',
  stripeClientId: 'ca_KZH9OePpyuv00W2iqI5EueNbFQoOvIA0',
  awsRegion: 'ap-south-1',
  shopifyApiUrl: 'https://vsonvnllqvd4lbf2odx4z7y2gq.appsync-api.ap-south-1.amazonaws.com/graphql',
  shopifyCallbackUrl: 'https://b987sj0rnk.execute-api.ap-south-1.amazonaws.com/dev/shopify',
  tiktokApiUrl: 'https://b53a4t7ptjdgrdu7j7or6iz4be.appsync-api.ap-south-1.amazonaws.com/graphql',
  tinaUrl: `https://tina-cloud-starter-kappa-eight-29.vercel.app/admin?token=${TINA_ACCESS_TOKEN}`
};
export const stagingEnvironmentUrls = {
  authApiUrl: 'https://auth.staging.buysocial.app/graphql',

  ordersAPIHost: 'orders.staging.buysocial.app',
  ordersApiUrl: 'https://orders.staging.buysocial.app/graphql',

  LiveStreamingAPIHost: 'live.staging.buysocial.app',
  liveStreamingUrl: 'https://live.staging.buysocial.app/graphql',
  realTimeLiveStreamingApiHost: 'wss://live.staging.buysocial.app/graphql/realtime',

  productsApiHost: 'products.staging.buysocial.app',
  productsApiUrl: 'https://products.staging.buysocial.app/graphql',

  passwordEncryptionKey: 'Fe(w}Xg2,EdkQ]Vcaf-CqYHKN2Fd',
  shippoClientId: 'bd15fd76036e40aba0b8a714f3df52bf',

  firebaseApiKey: 'AIzaSyB0hDy2kkB5IyGsrxReveBjrxRxe6ivH-w',
  firebaseAuthDomain: 'buysocial-staging.firebaseapp.com',
  firebaseTenantId: 'Tenant', // No validation on BE

  liveWebSocketUrl: 'wss://ws.staging.buysocial.app/live',
  facebookAppId: '3730770460484928',
  onesignalAppId: '0bf376f6-ee1e-41a5-8489-233a4fcb0c2d',
  csvBoxLicenseKey: '3z0SWCvvDfDeOaWrG4wWr9oZSVtdES',
  csvBoxImportURL: 'https://api.staging.buysocial.app/products/csvbox/webhook/products',
  stripeClientId: 'ca_PQrCbU3fXgphPc3PbhsP9eR9RrWbERjF',
  awsRegion: 'us-east-1',

  shopifyApiUrl: 'https://shopify.staging.buysocial.app/graphql',
  shopifyApiKey: 'da2-3e3hiw3qqnaaxgcy2nabdqp7iq',
  shopifyCallbackUrl: 'https://b987sj0rnk.execute-api.ap-south-1.amazonaws.com/dev/shopify',

  tiktokApiUrl: 'https://tiktok.staging.buysocial.app/graphql',
  tinaUrl: `https://webstore-staging-ebon.vercel.app/admin?token=${TINA_ACCESS_TOKEN}`,
  tiktokApiKey: ''
};

export const prodEnvironmentUrls = {
  authApiUrl: 'https://auth.trybuysocial.com/graphql',

  ordersAPIHost: 'orders.trybuysocial.com',
  ordersApiUrl: 'https://orders.trybuysocial.com/graphql',

  LiveStreamingAPIHost: 'live.trybuysocial.com',
  liveStreamingUrl: 'https://live.trybuysocial.com/graphql',
  realTimeLiveStreamingApiHost: 'wss://live.trybuysocial.com/graphql/realtime',

  productsApiHost: 'products.trybuysocial.com',
  productsApiUrl: 'https://products.trybuysocial.com/graphql',

  passwordEncryptionKey: 'ewiu(*9QoND^897vTH',
  shippoClientId: '5ac8ea5bbbd44d62a43e9343b9d25ab6',

  firebaseApiKey: 'AIzaSyANA9NmI-SdRBdiYNnBQgfVGXAR1igu0Nw',
  firebaseAuthDomain: 'buysocial-e7df1.firebaseapp.com',
  firebaseTenantId: 'Tenant', // No validation on BE

  liveWebSocketUrl: 'wss://ws.trybuysocial.com/live',
  facebookAppId: '',
  onesignalAppId: '22c56fe3-3229-4a75-86ec-821f7f3a84f6',
  csvBoxLicenseKey: '',
  csvBoxImportURL: '',
  stripeClientId: 'ca_RpV42iVBO2pRilXXqciKWSTeXt6MUgE7',
  awsRegion: 'us-east-1',

  shopifyApiUrl: 'https://shopify.trybuysocial.com/graphql',
  shopifyCallbackUrl: 'https://b987sj0rnk.execute-api.ap-south-1.amazonaws.com/dev/shopify',

  tiktokApiUrl: 'https://tiktok.trybuysocial.com/graphql',
  tinaUrl: `https://tina-cloud-starter-prod-eight.vercel.app/admin?token=${TINA_ACCESS_TOKEN}`,
};

const getEnvUrls = () => {
  // const env = devEnvironmentUrls;
  let env;
  switch (window['app'].env.REACT_APP_ENV) {
    case ENV.PROD:
      env = prodEnvironmentUrls;
      break;
    case ENV.QA:
      env = qaEnvironmentUrls;
      break;
    case ENV.LOCAL:
      env = localEnvironmentUrls;
      break;
    case ENV.STAGING:
      env = stagingEnvironmentUrls;
      break;
    default:
      env = devEnvironmentUrls;
      break;
  }
  return env;
};

const getEnvStatus = () => {
  switch (window['app'].env.REACT_APP_ENV) {
    case ENV.QA:
      return ENV.QA;
    case ENV.LOCAL:
      return ENV.LOCAL;
    case ENV.STAGING:
      return ENV.QA;
    default:
      return ENV.DEV;
  }
};

const envUrls = getEnvUrls();
const envStatuses = getEnvStatus();

export { envUrls, envStatuses };
