import { gql } from '@apollo/client';

export const SIGN_UP_BACK_STORE = gql`
  mutation signupBackstoreUser($input: SignupBackstoreUserInput!) @api(name: "authAPI") {
    signupBackstoreUser(input: $input) {
      email
      fullName
      profileUrl
      userId
      storeId
      storeName
      medium
      isUserExist
    }
  }
`;
