import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import { LiveshowCalendarComponent } from 'src/components/molecules';
import { CustomModal, DiscardModal, PageHeader, LiveEventPreview } from 'src/components/oraganisms';
import { CreateLiveShow } from 'src/components/templates';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { LiveShowCalendarEvents } from './LiveShowCalendar.types';

// Styles
import './_liveShowCalendar.scss';
import { ListViewIcon, CalendarViewIcon } from 'src/assets/icons';
import { KTSVG } from '../../../../helpers';
import LiveShowListView from '../../../oraganisms/LiveshowSearch/LiveShowListView';

export interface LiveShowCalendarProps {
  showLiveShowModal: boolean;
  setShowLiveShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  eventList: LiveShowCalendarEvents[];
  editEventData: any;
  updateData: () => void;
  deleteEvent: (eventId: string) => void;
  editEvent: (eventId: string, isComplete: boolean) => void;
  showDeleteModal: boolean;
  setShowDeleteModal: (value: boolean) => void;
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;
  currentMonth: Date;
}

const LiveShowCalendarPresentational: FC<LiveShowCalendarProps> = ({
  setCurrentMonth,
  showLiveShowModal,
  setShowLiveShowModal,
  eventList,
  editEventData,
  updateData,
  editEvent,
  deleteEvent,
  setShowDeleteModal,
  showDeleteModal,
  currentMonth
}) => {
  const location = useLocation();
  // State params
  const [showDetailSlider, setShowDetailSlider] = useState<boolean>(false);
  const [openSliderAction, setOpenSliderAction] = useState<string>('');
  const [eventDetails, setEventDetails] = useState<LiveShowCalendarEvents>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [defaultStartTime, setDefaultStartTime] = useState<string>('');

  useEffect(() => {
    if (editEventData) {
      setIsEditing(true);
      setShowLiveShowModal(true);
    } else {
      setIsEditing(false);
      closeLiveshowModal();
    }
  }, [editEventData]);
  useEffect(() => {
    setShowDetailSlider(false);
    if (showDetailSlider && eventDetails) {
      const updatedEvent = eventList.find((event) => event.id === eventDetails.id);
      setEventDetails(updatedEvent);
    }
  }, [eventList]);

  // Breadcrumbs
  const breadCrumbs = [
    {
      title: constVariables.LiveShow.liveShowTitle,
      path: ROUTES.liveShow.calendar.main,
      isActive: false
    },
    {
      title: constVariables.LiveShow.calendar,
      path: ROUTES.liveShow.calendar.main,
      isActive: true
    }
  ];

  // Button clicks
  const goToLiveShow = () => {
    history.push(ROUTES.liveShow.management.main);
  };
  const toggleLiveShowModal = (): void => {
    setIsEditing(false);
    if (showLiveShowModal) {
      setDefaultStartTime('');
    }
    if (!showLiveShowModal) {
      document.body.style.overflow = 'unset';
    }
    setShowLiveShowModal(!showLiveShowModal);
  };
  const hideDetailSlider = (): void => {
    setShowDetailSlider(false);
  };
  const showEventDetail = (eventObj: LiveShowCalendarEvents) => {
    setShowDetailSlider(true);
    setEventDetails(eventObj);
    setOpenSliderAction('hover');
  };

  const goToLiveShowManager = () => {
    history.push({
      pathname: ROUTES.liveShow.management.main,
      state: {
        eventDetails
      }
    });
  };

  // Extra Elements
  const createLiveShow = () => (
    <div className="position-relative w-300px ms-5">
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary btn-md" onClick={() => toggleLiveShowModal()}>
          {constVariables.LiveShow.createLiveShow}
        </button>
      </div>
    </div>
  );
  const history = useHistory();
  const backbtnHandler = () => {
    history.goBack();
  };

  const closeDeleteModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteModal(false);
  };

  const closeLiveshowModal = () => {
    document.body.style.overflow = 'unset';
    setShowLiveShowModal(false);
  };

  const [isListView, setIsListView] = useState(true);

  const renderListViewSwitch = () => {
    return (
      <div className="d-flex align-items-center justify-content-start m-l-20">
        <div
          onClick={() => setIsListView(true)}
          className={`valueBoxes valueBoxesLeft ${isListView ? 'valueBoxSelected' : 'valueBoxNotSelected'}`}
        >
          <KTSVG path={ListViewIcon} className="mx-1 my-auto" />
          {constVariables.LiveShow.listView}
        </div>
        <div
          onClick={() => setIsListView(false)}
          className={`valueBoxes valueBoxesRight  ${isListView ? 'valueBoxNotSelected' : 'valueBoxSelected'} `}
        >
          <KTSVG path={CalendarViewIcon} className="mx-1 my-auto" />
          {constVariables.LiveShow.calendarView}
        </div>
      </div>
    );
  };

  return (
    <>
      {showDeleteModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showDeleteModal} closeModal={closeDeleteModal}>
          <DiscardModal
            deleteProductMedia
            title={constVariables.LiveShow.deleteModal.title}
            message={constVariables.LiveShow.deleteModal.mainMessage}
            actionBtnTitle={constVariables.LiveShow.deleteModal.action}
            cancelBtnTitle={constVariables.LiveShow.deleteModal.cancel}
            actionBtnHandler={() => {
              if (deleteId) {
                deleteEvent(deleteId);
              }
              setDeleteId(undefined);
            }}
            cancelBtnHandler={closeDeleteModal}
          />
        </CustomModal>
      )}
      <div>
        <div className={`width-animation w-100 d-flex flex-column hide-scrollbar min-h-100 p-40`}>
          <div className="live-show-calendar-div">
            <div className={`w-100`}>
              <PageHeader
                backButton={location.pathname.includes(ROUTES.liveShow.calendar.search)}
                backbtnHandler={backbtnHandler}
                pageTitle={
                  location.pathname.includes(ROUTES.liveShow.calendar.search)
                    ? constVariables.LiveShow.searchLiveshow
                    : constVariables.LiveShow.liveShowCalendar
                }
                addButton={constVariables.LiveShow.goLiveNow}
                removeExportImport={true}
                onAddButton={goToLiveShow}
                HeaderRowElement={createLiveShow}
                HeadingExtenstion={location.pathname.includes(ROUTES.liveShow.calendar.search) ? undefined : renderListViewSwitch}
              />
              {(!isListView || location.pathname.includes(ROUTES.liveShow.calendar.search)) && (
                <LiveshowCalendarComponent
                  currentMonth={currentMonth}
                  setCurrentMonth={setCurrentMonth}
                  createLiveShow={createLiveShow}
                  eventList={eventList}
                  showEventDetail={showEventDetail}
                  toggleLiveShowModal={toggleLiveShowModal}
                  setDefaultStartTime={setDefaultStartTime}
                />
              )}
              {isListView && !location.pathname.includes(ROUTES.liveShow.calendar.search) && (
                <LiveShowListView handleSelectLiveshow={showEventDetail} />
              )}
            </div>
          </div>
        </div>
        <LiveEventPreview
          isVisible={showDetailSlider}
          goToLiveShow={goToLiveShowManager}
          hideSlider={hideDetailSlider}
          eventDetails={eventDetails}
          sliderAction={openSliderAction}
          setSliderAction={setOpenSliderAction}
          closeSlider={() => setShowDetailSlider(false)}
          editEvent={(eventId, isComplete) => editEvent(eventId, isComplete)}
          deleteEvent={(eventId) => {
            setDeleteId(eventId);
            setShowDeleteModal(true);
          }}
        />
      </div>
      {showLiveShowModal && (
        <CustomModal
          bodyClassname="d-flex justify-content-center w-90 w-md-150"
          show={showLiveShowModal}
          closeModal={closeLiveshowModal}
        >
          <CreateLiveShow
            setIsUpdated={updateData}
            isEditing={isEditing}
            editLiveInfo={isEditing ? editEventData : null}
            hideModal={toggleLiveShowModal}
            resetValue={showLiveShowModal}
            defaultStartTime={defaultStartTime}
          />
        </CustomModal>
      )}
    </>
  );
};

export default LiveShowCalendarPresentational;
