import { gql } from '@apollo/client';

export const GET_SHIPPING_SETTINGS = gql`
  query GET_SHIPPING_SETTINGS @api(name: "authAPI") {
    getShippingSettings {
      __typename
      id
      mode
      flatRate
      variableRate
      maximumRate
      isFreeShippingWindow
      freeShippingWindowCondition
      minimumPurchaseAmount
      minimumQtyOfItems
      freeShippingWindowOpenFor
      freeShippingWindowDuration
    }
  }
`;
