import { gql } from '@apollo/client';

export const UPSERT_SHIPPING_SETTINGS = gql`
  mutation UpsertShippingSettings($input: UpsertShippingSettingInput!) @api(name: "authAPI") {
    upsertShippingSettings(input: $input) {
      id
      mode
      flatRate
      variableRate
      maximumRate
      isFreeShippingWindow
      freeShippingWindowCondition
      minimumPurchaseAmount
      minimumQtyOfItems
      freeShippingWindowOpenFor
      freeShippingWindowDuration
    }
  }
`;
