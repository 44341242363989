import {useState, useEffect, FC, memo, useMemo} from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';

// Components
import { CouponsList, EmptyList, ScreenHeader } from 'src/components/oraganisms';
import { Pagination } from 'src/components/molecules';

// Icons
import { EmptyCouponListIllustration } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { AllCouponsPagePresentationalProps } from './AllCouponsPage.type';
import { ColumnType } from 'src/components/molecules/Table/Table.types';

const initialCouponTableColumns: ColumnType[] = [
  { name: 'Status', status: true },
  { name: 'Method', status: true },
  { name: 'Type', status: true },
  { name: 'Used', status: true }
];

const AllCouponsPagePresentational: FC<AllCouponsPagePresentationalProps> = (props) => {
  const {
    setSelectedTab,
    selectedTab,
    couponsList,
    setCouponsList,
    sortBy,
    setSortBy,
    searchedValue,
    isLoading,
    totalCount,
    getCouponInput,
    setCouponInput
  } = props;

  const history = useHistory();
  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);

  useEffect(() => {
    const tableColumns = localStorage.getItem('couponTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialCouponTableColumns);
    }
  }, []);

  const handleCreateOrder = () => history.push(ROUTES.coupons.createCoupon.main);

  const isEmptyTable = useMemo(() => {
    return !isLoading && totalCount === 0 && !searchedValue;
  }, [isLoading, totalCount, searchedValue])

  return (
    <div className="p-40" id="tablePage">
      <div className="width-animation w-100 d-flex flex-column hide-scrollbar">
        <ScreenHeader
          headerButtonText={constVariables.Coupons.AllCouponMenu.createCoupon}
          headerTitle={constVariables.Coupons.coupons}
          headerButtonClick={handleCreateOrder}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabList={constVariables.TabForCoupons}
          editColumnText={isEmptyTable ? '' : constVariables.couponEditColumns.title}
          editColumnData={editColumnData}
          setEditColumnData={setEditColumnData}
          openEditColumnDropdown={openEditColumnDropdown}
          setOpenEditColumnDropdown={setOpenEditColumnDropdown}
          isAllSwitchableColumns={true}
        />

        <div className="card w-100 p-0 mb-0">
          <div className="p-x-32 p-t-32">
            {!isLoading && totalCount === 0 && !searchedValue && (
              <>
                {selectedTab === constVariables.CouponsTab.allCoupons.toUpperCase() ? (
                  <EmptyList
                    title={constVariables.Coupons.AllCouponMenu.noTitle}
                    description={constVariables.Coupons.AllCouponMenu.noDescription}
                    addButtonText={constVariables.Coupons.AllCouponMenu.createCoupon}
                    onAddButton={() => history.push('/coupons/createCoupon')}
                    icon={EmptyCouponListIllustration}
                  />
                ) : (
                  <EmptyList datatype="coupon" status={selectedTab} icon={EmptyCouponListIllustration} />
                )}
              </>
            )}
            <CouponsList
              couponsList={couponsList}
              setCouponsList={setCouponsList}
              setCouponInput={setCouponInput}
              searchText={searchedValue}
              selectedTab={selectedTab}
              setSortBy={setSortBy}
              sortBy={sortBy}
              isLoading={isLoading}
              editColumnData={editColumnData}
            />
          </div>

          <div className={clsx('w-100', { 'display-none': totalCount === 0 })}>
            <Pagination
              total={totalCount}
              limitCount={getCouponInput?.pagination?.limitCount}
              skipCount={getCouponInput?.pagination?.skipCount}
              setSkipCount={(num: number) =>
                setCouponInput((prev) => ({
                  ...prev,
                  pagination: {
                    ...prev.pagination,
                    skipCount: num
                  }
                }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AllCouponsPagePresentational);
