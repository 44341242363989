/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { signOut } from 'src/helpers/InitFirebase';
import { envUrls } from 'src/constants';
import { cache } from './cache';
import 'firebase/app';

import { getNewFirebaseToken } from 'src/utils/authentication/authConfig';
import { userLoginData } from './reactiveVariables';
import { setContext } from 'apollo-link-context';
import firebase from 'firebase/app';

function getValidatedUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

const openOperations = {
  forgetpassword: true,
  resetpassword: true,
  resendUserEmailVerify: true,
  verifyUserMail: true,
  checkBackstoreUser: true,
  SignupBackstoreInvitee: true
};

const authMiddleware = setContext(async (operation) => {
  const user = await getValidatedUser();
  return getNewFirebaseToken().then((token) => {
    const header = {
      authorization: token || null
    };
    if (
      operation.operationName === 'ResetPasswordMutation' ||
      operation.operationName === 'verifyUserMail' ||
      operation.operationName === 'onChangeEmailMutation'
    ) {
      // eslint-disable-next-line dot-notation
      header['token'] = operation.variables?.token;
      // eslint-disable-next-line dot-notation
      header['tenantid'] = operation.variables?.tenantid;
    } else if (operation.operationName === 'resendUserEmailVerify') {
      // eslint-disable-next-line dot-notation
      header['tenantid'] = operation.variables?.tenantid;
    }

    if (operation?.operationName && openOperations[operation?.operationName]) {
      header.authorization = 'OPEN_ENDPOINT';
    }

    return {
      headers: header
    };
  });
});

// MultiAPILink is used to create differet endpoints as per module,
// and use it in API call syntax

const httpLink = new MultiAPILink({
  endpoints: {
    productsAPI: envUrls.productsApiUrl,
    ordersAPI: envUrls.ordersApiUrl,
    authAPI: envUrls.authApiUrl,
    liveStreamingAPI: envUrls.liveStreamingUrl,
    shopifyAPI: envUrls.shopifyApiUrl,
    tiktokAPI: envUrls.tiktokApiUrl
  },
  httpSuffix: '',
  getContext: (endpoint, getCurrentContext) => {
    const headers = getCurrentContext().headers;
    if (!headers.tenantid) {
      headers.tenantid = localStorage.getItem('storeId') ? localStorage.getItem('storeId') : null;
    }

    return {
      headers
    };
  },
  createHttpLink: () => createHttpLink()
});

// NOTE: If it returns network error with status code 401 or 403,
// it will remove localstorage data or token and will redirect to login screen
// for grapqhl errors, it will simply print down in console for debugging purpose.

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, ${path}`);
      return null;
    });
  if (networkError) {
    console.error('[Network error]: ', networkError);
    const errorMsg: string = networkError.message ? networkError.message : networkError.toString();
    if (errorMsg.includes('401') || errorMsg.includes('403')) {
      signOut();
      localStorage.clear();
      userLoginData({});
      window.location.reload();
      // This function will replace previous routes which are already in the history of react router
      window.history.replaceState(null, '/');
    }
  }
});

const link = ApolloLink.from([errorLink, authMiddleware as unknown as ApolloLink, httpLink]);

export const client = new ApolloClient({
  cache,
  link
});
