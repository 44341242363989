import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation resetpassword($input: ResetPasswordInput!) @api(name: "authAPI") {
    resetPassword(input: $input) {
      success
      message
      userId
    }
  }
`;
