/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import IVSBroadcastClient, { AmazonIVSBroadcastClient, ConnectionState } from 'amazon-ivs-web-broadcast';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ON_START_LIVESTREAM, ON_ENDING_LIVESTREAM, ADD_LIVE_STREAM } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox, DropdownSelector, Loader } from 'src/components/atoms';
import { CustomModal, PageHeader, LeaveLiveShowConfirmModal, ErrorOnDashboardModal } from 'src/components/oraganisms';
import { Dropdown, VideoControllerButton } from 'src/components/molecules';
import { CreateUnplannedLiveshow } from 'src/components/templates';
import IvsPlayer from './components/IvsPlayer';
import LiveshowFeed, { LiveshowFeedRef, wsDataType } from './LiveshowFeed';
import LiveSettings from './LiveSettings';
import CountDownModal from './CountDownModal';

// Hooks && Utils && Helpers
import { decrypt } from 'src/utils/encrypt/encrypt';
import { countDown } from 'src/utils/countdown';
import { isTimeExpired } from 'src/utils/isTimeExpired';
import { defaultLiveShowData, defaultliveShowPlannedInfo, getLiveShowPlanedInfoFromEventDetails } from './helpers/defaultData';
import { leavePageHandler } from './helpers/navigation';
import useSellerApp from './hooks/useSellerApp';
import useMediaDevices from './hooks/useMediaDevices';
import useFacebookComments from './hooks/useFacebookComments';
import { useStream } from 'src/components/oraganisms/Stream';

// Icons
import {
  MicrophoneGrey,
  VideoCameraGrey,
  VideoCameraDisabled,
  VideoCamera,
  MicrophoneDisabled,
  Microphone
} from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { LiveShowManagerPresentationalProps, LiveShowManagerPresentationalRef } from './LiveshowManager.types';
import { UnplannedLiveShowDetailInfo } from 'src/components/templates/CreateUnplannedLiveshow/CreateUnplannedLiveshow.types';
import { LiveShowDetailInfo } from 'src/components/oraganisms/CreateLiveShowDetailStep/CreateLiveShowDetailStep.types';

// Styles
import './_liveshowManager.scss';
import { GET_LIVE_SHOW_PRODUCTS } from 'src/apollo/queries';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';

let RUNING_LIVE_ID = '';
// const LiveShowManagerPresentational: React.FC<LiveShowManagerPresentationalProps> = ({
const LiveShowManagerPresentational = forwardRef<LiveShowManagerPresentationalRef, LiveShowManagerPresentationalProps>(
  (
    {
      showLiveShowModal,
      setShowLiveShowModal,
      nextLiveStream,
      storeStreamConfigurator,
      setStoreStreamConfigurator,
      eventDetails,
      handleGetNextScheduledLiveShow
    },
    ref
  ) => {
    const { liveVideo, previewVideo, runningLiveId, runningStreamSource } = storeStreamConfigurator;
    const { ingestServer } = liveVideo;

    const { role } = useAppSelector((state) => state.userInfo.userInfo);

    const client = useRef<AmazonIVSBroadcastClient>();
    const liveshowRef = useRef<any>();
    const intervalref = useRef<NodeJS.Timer>();
    const liveFeedRef = useRef<LiveshowFeedRef>(null);

    const location = useLocation();
    const history = useHistory();
    const { toggleStream } = useStream();
    const { startSellerApp, startStopLoading, stopSellerApp } = useSellerApp();
    const {
      stopMediaDevices,
      devicePermissions,
      handleError,
      initLayers,
      videoDevices,
      audioDevices,
      camMuted,
      micMuted,
      activeAudioDevice,
      activeVideoDevice,
      handleCameraMute,
      handleMicMute,
      renderActiveAudioDevice,
      renderActiveVideoDevice,
      canvasRef
    } = useMediaDevices(client);

    const startedLiveshowIdRef = useRef('');
    const [startedLiveshowId, setStartedLiveShowId] = useState('');

    const [countDownTime, setCountDownTime] = useState('');
    const [startBlockedTill, setStartBlockedTill] = useState('');

    const [counter, setCounter] = useState(5);
    const [showCounterModal, setShowCounterModal] = useState(false);
    const [isStreamSourceDropdown, setIsStreamSourceDropdown] = useState(false);
    const [selectedStreamSource, setSelectedStreamSource] = useState<string>(runningStreamSource || 'Seller app');

    const [eventStatus, setEventStatus] = useState('');
    const [liveShowInfo, setLiveShowInfo] = useState<UnplannedLiveShowDetailInfo>(defaultLiveShowData);

    const [liveShowPlannedInfo, setLiveShowPlannedInfo] = useState<LiveShowDetailInfo>(defaultliveShowPlannedInfo);

    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [targettedPath, setTargettedPath] = useState<string>('');
    const [locationState, setLocationState] = useState('');
    const [showDashboard, setShowDashboard] = useState(false);
    const [showDashboardError, setShowDashboardError] = useState(false);

    const [isStreamingFromCurrentDevice, setIsStreamingFromCurrentDevice] = useState(false);
    const [isStaredFromCurrentDevice, setIsStaredFromCurrentDevice] = useState(false);

    const streamKey = useMemo(() => (liveVideo?.streamKey ? decrypt(liveVideo?.streamKey) : ''), [storeStreamConfigurator]);

    const [addLiveStream, { loading: addLoading }] = useMutation(ADD_LIVE_STREAM, {});
    const [getLiveshowProducts] = useLazyQuery(GET_LIVE_SHOW_PRODUCTS);
    const [onEndingStream, { loading: endLoading }] = useMutation(ON_ENDING_LIVESTREAM);
    const [onStartLiveStream] = useMutation(ON_START_LIVESTREAM);

    useImperativeHandle(ref, () => ({
      onMessage: (data: wsDataType) => {
        liveFeedRef?.current?.onWsMessage(data);
      }
    }));

    useEffect(() => {
      if (selectedStreamSource === 'Desktop' && liveshowRef.current) {
        client?.current?.attachPreview(liveshowRef.current);
      }
    }, [selectedStreamSource, liveshowRef.current]);

    useEffect(() => {
      const getCountdown = () => {
        const now = new Date().getTime();
        const blockedTill = new Date(storeStreamConfigurator?.nextShowBlockedTill || 0).getTime();

        if (now < blockedTill) {
          const interval = setInterval(() => {
            const n = new Date().getTime();
            const diff = +(Math.abs(blockedTill - n) / 1000).toFixed(0);
            if (diff < 1) {
              setStartBlockedTill('');
              clearInterval(interval);
            } else {
              setStartBlockedTill(`${diff} s`);
            }
          }, 1000);
        }
      };
      getCountdown();
    }, [storeStreamConfigurator?.nextShowBlockedTill]);

    useEffect(() => {
      const IVSClient = IVSBroadcastClient.create({
        streamConfig: IVSBroadcastClient.STANDARD_PORTRAIT
      });

      client.current = IVSClient;
      return () => {
        stopMediaDevices(client?.current);
      };
    }, []);

    useEffect(() => {
      if (eventDetails) {
        if (new Date(eventDetails.startingAt) < new Date()) {
          setEventStatus('past');
          setCountDownTime('Live show Completed');
        }
        setLiveShowPlannedInfo(getLiveShowPlanedInfoFromEventDetails(eventDetails));
      }
    }, [eventDetails]);

    useEffect(() => {
      if (showDashboard) {
        const preventRoute = history.block((e: any) => {
          setIsConfirmationModalVisible(true);
          setTargettedPath(e.pathname);
          setLocationState(e.state);
          return false;
        });

        return () => {
          preventRoute();
        };
      }
    }, [history, showDashboard]);

    useEffect(() => {
      if (runningLiveId) {
        setShowDashboard(true);
      } else if (!isStaredFromCurrentDevice) {
        setShowDashboard(false);
      }
    }, [isStaredFromCurrentDevice, runningLiveId]);

    // useEffect(() => {
    //   const getCountdown = () => {
    //     if (liveShowPlannedInfo.startingAt && new Date(eventDetails.startingAt) > new Date()) {
    //       setInterval(() => {
    //         const duration = countDown(liveShowPlannedInfo.startingAt);
    //         setEventStatus(isTimeExpired(liveShowPlannedInfo.startingAt, liveShowPlannedInfo.endingAt));
    //         if (duration.length > 0) {
    //           setCountDownTime(duration);
    //         } else {
    //           handleGetNextScheduledLiveShow();
    //         }
    //       }, 1000);
    //     }
    //   };
    //   getCountdown();
    // }, [liveShowPlannedInfo]);
    // Stream source selection
    const toggleStreamSourceDropDown = () => {
      setIsStreamSourceDropdown((prev) => !prev);
    };

    const closeStreamSourceDropdown = () => {
      setIsStreamSourceDropdown(false);
    };

    const selectStreamSource = (data: { id: number; name: string }) => {
      closeStreamSourceDropdown();
      if (data.name.toUpperCase() === 'SELLER APP') {
        setSelectedStreamSource('Seller app');
        stopMediaDevices(client?.current);
      } else if (data.name.toUpperCase() === 'DESKTOP') {
        setSelectedStreamSource('Desktop');
        initLayers();
      }
    };

    const toggleLiveShowModal = useCallback((): void => {
      if (liveShowPlannedInfo.title) {
        return;
      }
      setShowLiveShowModal((b) => !b);
    }, [liveShowPlannedInfo.title, setShowLiveShowModal]);

    const onStartStremToIvs = () => {
      if (selectedStreamSource === 'Seller app') {
        startSellerApp();
      } else {
        handleStream();
      }
      if (liveShowInfo.title) {
        return '';
      } else if (liveShowPlannedInfo.title) {
        return liveShowPlannedInfo.id;
      }
    };

    const onDashboardErrors = (err) => {
      stopStreamToIvs();
      console.log(err);
      setShowDashboardError(true);
      setIsStreamingFromCurrentDevice(false);
    };

    const onExitDashboard = async () => {
      console.log('called exit');
      document.body.style.overflow = 'unset';
      setShowDashboard(false);
      setShowDashboardError(false);
    };

    const handleCloseTimerModal = () => {
      console.log('called close timer modal');
      stopStreamToIvs();
      closeCounterModal();
      clearInterval(intervalref.current);
      setCounter(5);
      setIsStaredFromCurrentDevice(false);
      setIsStreamingFromCurrentDevice(false);
    };

    const closeConfirmModal = () => {
      document.body.style.overflow = 'unset';
      setIsConfirmationModalVisible(false);
    };

    const closeCounterModal = () => {
      document.body.style.overflow = 'unset';
      setShowCounterModal(false);
    };
    const closeLiveshowModal = () => {
      document.body.style.overflow = 'unset';
      setShowLiveShowModal(false);
    };

    const doPreactions = useCallback(async () => {
      let liveShowId = onStartStremToIvs();
      setIsStaredFromCurrentDevice(true);
      if (selectedStreamSource === 'Desktop') {
        setIsStreamingFromCurrentDevice(true);
      }
      if (!liveShowId) {
        let count = 100;

        // Mapping products with incremental count value
        const liveShowProducts = liveShowInfo.products.map(({ productId }) => ({
          productId,
          productReferencedId: ++count
        }));

        // Creating liveshowData depending on whether liveShowPlannedInfo exists
        const liveshowData = liveShowPlannedInfo.title
          ? liveShowPlannedInfo
          : {
              ...liveShowInfo,
              products: liveShowProducts,
              thumbnailUrl: liveShowInfo.thumbnailUrl.url
            };

        try {
          // Adding live stream and setting the live show ID
          const result = await addLiveStream({
            variables: {
              input: liveshowData
            }
          });
          liveShowId = result?.data?.addLiveStream?.addedLiveStreamId;
        } catch (error) {
          onDashboardErrors(error);
        }
      }

      getLiveshowProducts({
        variables: {
          id: liveShowId
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      startedLiveshowIdRef.current = liveShowId!;
      emitCustomEvent('onLiveDashboard', { runningLiveId: liveShowId! });
    }, [onStartStremToIvs, liveShowInfo, liveShowPlannedInfo, addLiveStream, onDashboardErrors, setIsStaredFromCurrentDevice]);

    const startStreamToBuysocial = useCallback(async () => {
      setShowDashboard(true);
      closeCounterModal();

      emitCustomEvent('onLiveDashboard', { runningLiveId: startedLiveshowIdRef?.current });
      setStartedLiveShowId(startedLiveshowIdRef?.current);
      onStartLiveStream({
        variables: {
          id: startedLiveshowIdRef?.current,
          runningStreamSource: selectedStreamSource === 'Seller app' ? 'SELLER_APP' : 'BACKSTORE'
        },
        // onCompleted: (d) => {
        //   setStoreStreamConfigurator(d?.onStartingLiveStream);
        // },
        onError: onDashboardErrors
      });
    }, [
      selectedStreamSource,
      setShowDashboard,
      closeCounterModal,
      setIsStreamingFromCurrentDevice,
      setIsStaredFromCurrentDevice,
      setStartedLiveShowId,
      onStartLiveStream,
      startedLiveshowIdRef,
      onDashboardErrors,
      client,
      liveshowRef
    ]);

    // Starting stream
    const handleGoLive = useCallback(async () => {
      if (liveShowInfo.title || liveShowPlannedInfo.title) {
        setShowCounterModal(true);
        doPreactions();
        await new Promise((resolve) => {
          intervalref.current = setInterval(() => {
            setCounter((c) => {
              if (c > 1) {
                return c - 1;
              } else {
                clearInterval(intervalref.current);
                startStreamToBuysocial();
                setCounter(5);
                resolve(c);
                return c;
              }
            });
          }, 1000);
        });
      } else {
        toggleLiveShowModal();
      }
    }, [liveShowInfo, liveShowPlannedInfo, doPreactions, toggleLiveShowModal, startStreamToBuysocial]);

    const handleStream = async () => {
      if (ingestServer && streamKey) {
        toggleStream(ingestServer, streamKey, client.current, handleError);
      }
    };

    const handleStopStream = useCallback(async () => {
      RUNING_LIVE_ID = runningLiveId;
      await onEndingStream({
        variables: {
          id: runningLiveId
        },
        onCompleted: onStopStreamToBuysocial,
        onError: onStopStreamToBuysocial
      });
    }, [runningLiveId]);

    const onStopStreamToBuysocial = async (res) => {
      emitCustomEvent('onEndLiveDashboard');
      console.log('RUNING_LIVE_ID', RUNING_LIVE_ID);
      await stopStreamToIvs();
      if (RUNING_LIVE_ID) {
        return (window.location.href = `/liveShow/report/${runningLiveId}?isEndLive=true`);
      }
      setIsConfirmationModalVisible(false);
      setShowDashboard(false);
      startedLiveshowIdRef.current = '';
      setStartedLiveShowId('');
      leavePageHandler(locationState, history, targettedPath);
    };

    const stopStreamToIvs = async () => {
      if (selectedStreamSource === 'Seller app') {
        await stopSellerApp();
      } else if (client?.current?.getConnectionState() === ConnectionState.CONNECTED) {
        client?.current?.stopBroadcast();
      }
    };

    const [isStartingVideo, setIsStartingVideo] = useState(false);

    useEffect(() => {
      if (devicePermissions.video) {
        setIsStartingVideo(true);
      }
    }, [devicePermissions.video]);

    useCustomEventListener('addVideoLayerDone', () => {
      setIsStartingVideo(false);
    });

    return (
      <>
        {!showDashboard ? (
          <FlexBox>
            <div className={`width-animation w-100 d-flex flex-column hide-scrollbar min-h-100 m-r-40`}>
              <div className={`w-100 p-l-40`}>
                <div className="liveshow-streamer-video-container w-100 min-vh-100">
                  <PageHeader
                    backButton={location.pathname.includes(ROUTES.liveShow.calendar.search)}
                    backbtnHandler={history.goBack}
                    pageTitle={
                      location.pathname.includes(ROUTES.liveShow.calendar.search)
                        ? constVariables.LiveShow.searchLiveshow
                        : constVariables.LiveShow.liveShowManager
                    }
                    removeExportImport={true}
                  />
                  <FlexBox className="liveshow-streamer-video w-100">
                    <FlexBox className="w-100 justify-content-between">
                      <p className="m-0">
                        {liveShowPlannedInfo.title
                          ? liveShowPlannedInfo.title
                          : liveShowInfo.title
                          ? liveShowInfo.title
                          : constVariables.LiveShow.newLiveShow}
                      </p>
                      <div className="position-relative">
                        <DropdownSelector
                          className={`form-control form-control-lg justify-content-between align-items-center cursor-pointer dropdown-box ${
                            isStreamSourceDropdown ? 'dropdown-box-active' : ''
                          }`}
                          onClick={toggleStreamSourceDropDown}
                          selectedValue={selectedStreamSource}
                          text="Select"
                        />
                        <OutsideClickHandler onOutsideClick={closeStreamSourceDropdown}>
                          <Dropdown
                            data={constVariables.LiveShow.liveshowManagerDropDown}
                            selected={isStreamSourceDropdown}
                            onSelect={selectStreamSource}
                            value={selectedStreamSource}
                            noScroll={true}
                            closeDropdown={() => setIsStreamSourceDropdown(false)}
                            className="full-width-dropdown"
                          />
                        </OutsideClickHandler>
                      </div>
                    </FlexBox>
                    {selectedStreamSource === 'Seller app' ? (
                      <FlexBox className="video-stream-container">
                        {previewVideo?.isStreaming ? (
                          // <video id="video-livestream-player" crossOrigin="anonymous" ref={video} playsInline />
                          <IvsPlayer playbackUrl={previewVideo?.playbackUrl} showControls={false} />
                        ) : (
                          <div className="no-video-placeholder">
                            <span>The seller app is not active, please turn it on to start broadcasting.</span>
                          </div>
                        )}
                        <div className="video-controller">
                          <VideoControllerButton image={VideoCameraGrey} />
                          <VideoControllerButton image={MicrophoneGrey} />
                          {eventStatus === 'upcoming' || startBlockedTill ? (
                            <div className="my-auto ms-3 me-0">
                              <button
                                disabled={!previewVideo?.isStreaming || addLoading || startStopLoading || !!startBlockedTill}
                                className="btn btn-light poppins-regular"
                                style={{ color: '#00A3FF', backgroundColor: '#00A3FF1A' }}
                                onClick={() => {
                                  if (!previewVideo?.isStreaming) {
                                    return;
                                  }
                                  handleGoLive();
                                }}
                              >
                                {startBlockedTill || countDownTime}
                                {(addLoading || startStopLoading) && <Loader type="button" className="h-15px w-15px" />}
                              </button>
                            </div>
                          ) : (
                            <div className="my-auto ms-3 me-0">
                              <button
                                disabled={!previewVideo?.isStreaming || addLoading || startStopLoading || role !== 'ROLE_OWNER'}
                                className="btn btn-primary btn-md"
                                onClick={() => {
                                  if (!previewVideo?.isStreaming) {
                                    return;
                                  }
                                  handleGoLive();
                                }}
                              >
                                {constVariables.LiveShow.goLiveNow}
                                {(addLoading || startStopLoading) && <Loader type="button" className="h-15px w-15px" />}
                              </button>
                            </div>
                          )}
                        </div>
                      </FlexBox>
                    ) : (
                      <FlexBox className="video-stream-container">
                        {isStartingVideo && (
                          <div className="no-video-float-placeholder">
                            <span>Starting camera...</span>
                          </div>
                        )}
                        {devicePermissions.video ? (
                          <>
                            <FlexBox className="justify-content-center preview-video-container">
                              <canvas
                                key="STREAM_PREVIEW_VIDEO"
                                className="preview-video-canvas"
                                id="cam-video-preview"
                                ref={canvasRef}
                              ></canvas>
                            </FlexBox>
                            <FlexBox className="controller">
                              <VideoControllerButton
                                renderActiveDevice={renderActiveVideoDevice}
                                activeDevice={activeVideoDevice}
                                isMuted={camMuted && !isStartingVideo}
                                sources={videoDevices}
                                onClick={() => {
                                  if (camMuted) {
                                    setIsStartingVideo(true);
                                  }
                                  handleCameraMute();
                                }}
                                image={isStartingVideo ? VideoCamera : camMuted ? VideoCameraDisabled : VideoCamera}
                              />
                              <VideoControllerButton
                                renderActiveDevice={renderActiveAudioDevice}
                                activeDevice={activeAudioDevice}
                                isMuted={micMuted}
                                sources={audioDevices}
                                onClick={handleMicMute}
                                image={micMuted ? MicrophoneDisabled : Microphone}
                              />
                              {eventStatus === 'upcoming' || startBlockedTill ? (
                                <div className="my-auto ms-3 me-0">
                                  <button
                                    disabled={addLoading || eventStatus === 'upcoming' || !!startBlockedTill}
                                    className="btn btn-secondary"
                                    onClick={() => {
                                      handleGoLive();
                                    }}
                                  >
                                    {startBlockedTill || countDownTime}
                                    {(addLoading || startStopLoading) && <Loader type="button" className="h-15px w-15px" />}
                                  </button>
                                </div>
                              ) : (
                                <div className="my-auto ms-3 me-0">
                                  <button
                                    disabled={addLoading || role !== 'ROLE_OWNER'}
                                    className="btn btn-primary btn-md"
                                    onClick={() => {
                                      handleGoLive();
                                    }}
                                  >
                                    {constVariables.LiveShow.goLiveNow}
                                    {(addLoading || startStopLoading) && <Loader type="button" className="h-15px w-15px" />}
                                  </button>
                                </div>
                              )}
                            </FlexBox>
                          </>
                        ) : (
                          <div>
                            <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                              <path d="m42.7 32.95-6.7-6.7v5.55l-3-3V11H15.2l-3-3H33q1.2 0 2.1.9.9.9.9 2.1v10.75l6.7-6.7q.35-.35.825-.175t.475.675v16.9q0 .5-.475.675-.475.175-.825-.175ZM41.35 45.6 2.95 7.2q-.4-.4-.4-1T3 5.15q.45-.45 1.05-.45.6 0 1.05.45l38.4 38.4q.4.4.4 1t-.45 1.05q-.45.45-1.05.45-.6 0-1.05-.45ZM24.2 20ZM7.95 8l3 3H7v26h26v-3.95l3 3V37q0 1.2-.9 2.1-.9.9-2.1.9H7q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8Zm11.9 16.1Z" />
                            </svg>
                            To start streaming, allow access to your camera and microphone
                          </div>
                        )}
                      </FlexBox>
                    )}
                  </FlexBox>
                </div>
              </div>
            </div>
            <LiveSettings
              nextLiveStream={nextLiveStream}
              liveShowInfo={liveShowInfo}
              liveShowPlannedInfo={liveShowPlannedInfo}
              toggleLiveShowModal={toggleLiveShowModal}
            />
          </FlexBox>
        ) : (
          <LiveshowFeed
            ref={liveFeedRef}
            liveshowId={storeStreamConfigurator?.runningLiveId || startedLiveshowId}
            endLoading={endLoading}
            liveVideo={liveVideo}
            camMuted={camMuted}
            micMuted={micMuted}
            liveshowRef={liveshowRef}
            handleCameraMute={handleCameraMute}
            handleMicMute={handleMicMute}
            handleStopStream={handleStopStream}
            storeStreamConfigurator={storeStreamConfigurator}
            isStreamingFromCurrentDevice={isStreamingFromCurrentDevice}
            setIsStaredFromCurrentDevice={setIsStaredFromCurrentDevice}
          />
        )}

        <CustomModal
          bodyClassname="d-flex justify-content-center w-90 w-md-150"
          show={showCounterModal}
          closeModal={closeCounterModal}
        >
          <CountDownModal handleCloseTimerModal={handleCloseTimerModal} counter={counter} />
        </CustomModal>

        <CustomModal
          bodyClassname="d-flex justify-content-center w-90 w-md-150"
          show={showLiveShowModal}
          closeModal={closeLiveshowModal}
        >
          <CreateUnplannedLiveshow
            liveShowInfo={liveShowInfo}
            setLiveShowInfo={setLiveShowInfo}
            toggleLiveShowModal={toggleLiveShowModal}
            onGoLive={handleGoLive}
          />
        </CustomModal>

        {isConfirmationModalVisible && (
          <CustomModal
            bodyClassname="d-flex justify-content-center w-md-150"
            show={isConfirmationModalVisible}
            closeModal={closeConfirmModal}
          >
            <LeaveLiveShowConfirmModal isEndLoading={endLoading} handleConfirm={handleStopStream} closeModal={closeConfirmModal} />
          </CustomModal>
        )}
        {showDashboardError && (
          <CustomModal
            bodyClassname="d-flex justify-content-center w-md-150"
            show={showDashboardError}
            closeModal={onExitDashboard}
          >
            <ErrorOnDashboardModal handleConfirm={onExitDashboard} />
          </CustomModal>
        )}
      </>
    );
  }
);

export default LiveShowManagerPresentational;
